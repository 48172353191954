<template>
  <CRow>
    <CCol col="12">
      <CCard v-if="itemData">
        <CCardHeader> التصنيف رقم: {{ $route.params.id }} </CCardHeader>
        <CCardBody>
          <CAlert color="info">
            <CRow>
              <CCol col="3">الاسم</CCol>
              <CCol col="3"> {{ itemData.name }} </CCol>
            </CRow>
          </CAlert>

          <CAlert color="info">
            <CRow>
              <CCol col="3">الموبايل</CCol>
              <CCol col="3"> {{ itemData.email }} </CCol>
            </CRow>
          </CAlert>
          
          <CAlert color="info">
            <CRow>
              <CCol col="3">التليفون</CCol>
              <CCol col="3"> {{ itemData.phone }} </CCol>
            </CRow>
          </CAlert>

          <CAlert color="info">
            <CRow>
              <CCol col="3"> الايميل </CCol>
              <CCol col="3"> {{ itemData.email }} </CCol>
            </CRow>
          </CAlert>

          <CAlert color="info">
            <CRow>
              <CCol col="3"> الحالة </CCol>
              <CCol col="3">
                <b :class="`text-${getBadge(itemData.status)}`">
                  {{ itemData.status }}
                </b>
              </CCol>
            </CRow>
          </CAlert>
          
          <CAlert color="info">
            <CRow>
              <CCol col="3"> الصورة </CCol>
              <CCol col="3">
                <CImg
                      src="https://pharmaceutical-journal.com/wp-content/uploads/2021/01/face-mask-category-pa-20-scaled.jpg"
                      fluid
                    />
              </CCol>
            </CRow>
          </CAlert>

        </CCardBody>
        <CCardFooter>
          <CButton color="info" @click="goBack">رجوع</CButton>
        </CCardFooter>
      </CCard>
      <Spinner v-else />
    </CCol>
  </CRow>
</template>

<script>
export default {
  name: "Category",
  data() {
    return {
      itemData: null,
      usersOpened: null,
    };
  },
  created() {
    this.getUser();
  },
  methods: {
    getUser() {
      this.toggleLoadSpinner();

      this.$http
        .get("users/" + this.$route.params.id)
        .then((res) => {
          // handle success
          this.itemData = res.data.data;
          this.toggleLoadSpinner();
        })
        .catch((error) => {
          //
          this.toggleLoadSpinner();
        });
    },
    toggleLoadSpinner() {
      this.$store.commit("toggleLoadSpinner");
    },
    goBack() {
      this.$router.push({ path: "/categories" });
    },
    getBadge(status) {
      switch (status) {
        case "Active":
          return "success";
        case "Inactive":
          return "secondary";
        case "Pending":
          return "warning";
        case "Banned":
          return "danger";
        default:
          "primary";
      }
    },
  },
};
</script>
